@import url(https://cdn.quilljs.com/1.2.6/quill.snow.css);


.logo {
  float: left;
  width: 120px;
  height: 100%;
  margin-right: 100px;
  background-color: transparent;
}
.site-layout .site-layout-background {
  background: #fff;
}
html {
  font-family: "Ubuntu", sans-serif !important;
}
body {
  font-family: "Ubuntu", sans-serif !important;
}

